

.scene {
    width: 400px;
    height: 400px;
    perspective: 1000px;
  }
  
  .cube {
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    transform: rotateX(0deg) rotateY(0deg);
    transition: transform 0.1s;
  }
  
  .face {
    position: absolute;
    width: 400px;
    height: 400px;
    background: rgba(255, 255, 255, 0.9);
    border: 1px solid #ccc;
    line-height: 400px;
    font-size: 40px;
    text-align: center;
    display: inline-flex;
  }
  
  .front  { transform: translateZ(200px); }
  .back   { transform: rotateY(180deg) translateZ(200px); }
  .left   { transform: rotateY(-90deg) translateZ(200px); }
  .right  { transform: rotateY(90deg) translateZ(200px); }
  .top    { transform: rotateX(90deg) translateZ(200px); }
  .bottom { transform: rotateX(-90deg) translateZ(200px); }