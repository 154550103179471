@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: Arial, Helvetica, sans-serif;
}

.heading {
    font-family: "Original Surfer", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.background_one {
    background-image: url("./assets/images/fondo1.png");
}