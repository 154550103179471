.custom-shape-divider-bottom-1722485639 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1722485639 svg {
    position: relative;
    display: block;
    width: calc(105% + 1.3px);
    height: 155px;
}

.custom-shape-divider-bottom-1722485639 .shape-fill {
    fill: #FFFFFF;
}

.primary-color {
    color: #00b3ee;
}

.box-graph {
    background-color: #b77239;
    color: white;
    border: 10px solid #ffac2e;
}
